import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _57062649 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1843b62c = () => interopDefault(import('../pages/_country/index.vue' /* webpackChunkName: "pages/_country/index" */))
const _283b83f9 = () => interopDefault(import('../pages/_country/about-us/index.vue' /* webpackChunkName: "pages/_country/about-us/index" */))
const _a64ae5c0 = () => interopDefault(import('../pages/_country/bag.vue' /* webpackChunkName: "pages/_country/bag" */))
const _27b8fbb4 = () => interopDefault(import('../pages/_country/become-a-dealer/index.vue' /* webpackChunkName: "pages/_country/become-a-dealer/index" */))
const _c1d59bfa = () => interopDefault(import('../pages/_country/contact-us.vue' /* webpackChunkName: "pages/_country/contact-us" */))
const _1842a75a = () => interopDefault(import('../pages/_country/cookie-policy.vue' /* webpackChunkName: "pages/_country/cookie-policy" */))
const _3b6f57e9 = () => interopDefault(import('../pages/_country/how-to-buy.vue' /* webpackChunkName: "pages/_country/how-to-buy" */))
const _84f46ffe = () => interopDefault(import('../pages/_country/login.vue' /* webpackChunkName: "pages/_country/login" */))
const _000056c8 = () => interopDefault(import('../pages/_country/manuals/index.vue' /* webpackChunkName: "pages/_country/manuals/index" */))
const _3b25f6b2 = () => interopDefault(import('../pages/_country/my-account/index.vue' /* webpackChunkName: "pages/_country/my-account/index" */))
const _4943d3ae = () => interopDefault(import('../pages/_country/news/index.vue' /* webpackChunkName: "pages/_country/news/index" */))
const _71e27faf = () => interopDefault(import('../pages/_country/privacy-policy.vue' /* webpackChunkName: "pages/_country/privacy-policy" */))
const _e27965a6 = () => interopDefault(import('../pages/_country/recovery.vue' /* webpackChunkName: "pages/_country/recovery" */))
const _9cd2758a = () => interopDefault(import('../pages/_country/register.vue' /* webpackChunkName: "pages/_country/register" */))
const _56b526c9 = () => interopDefault(import('../pages/_country/shipping-policy.vue' /* webpackChunkName: "pages/_country/shipping-policy" */))
const _d4060a6c = () => interopDefault(import('../pages/_country/stores.vue' /* webpackChunkName: "pages/_country/stores" */))
const _570e30d4 = () => interopDefault(import('../pages/_country/subscriber-verify.vue' /* webpackChunkName: "pages/_country/subscriber-verify" */))
const _c44839c2 = () => interopDefault(import('../pages/_country/terms.vue' /* webpackChunkName: "pages/_country/terms" */))
const _00a61d27 = () => interopDefault(import('../pages/_country/test-drive.vue' /* webpackChunkName: "pages/_country/test-drive" */))
const _6e6679d8 = () => interopDefault(import('../pages/_country/warranty.vue' /* webpackChunkName: "pages/_country/warranty" */))
const _e60300b8 = () => interopDefault(import('../pages/_country/work-with-us/index.vue' /* webpackChunkName: "pages/_country/work-with-us/index" */))
const _4830ba3a = () => interopDefault(import('../pages/_country/my-account/addresses/index.vue' /* webpackChunkName: "pages/_country/my-account/addresses/index" */))
const _eb3fec6c = () => interopDefault(import('../pages/_country/my-account/bikes/index.vue' /* webpackChunkName: "pages/_country/my-account/bikes/index" */))
const _362f3ee3 = () => interopDefault(import('../pages/_country/my-account/orders/index.vue' /* webpackChunkName: "pages/_country/my-account/orders/index" */))
const _49185ca0 = () => interopDefault(import('../pages/_country/my-account/orders/_code/index.vue' /* webpackChunkName: "pages/_country/my-account/orders/_code/index" */))
const _4202acc4 = () => interopDefault(import('../pages/_country/checkout/_code/index.vue' /* webpackChunkName: "pages/_country/checkout/_code/index" */))
const _ddd10678 = () => interopDefault(import('../pages/_country/news/_slug_id.vue' /* webpackChunkName: "pages/_country/news/_slug_id" */))
const _30271092 = () => interopDefault(import('../pages/_country/products/_slug.vue' /* webpackChunkName: "pages/_country/products/_slug" */))
const _6b56a7ec = () => interopDefault(import('../pages/_country/search/_type.vue' /* webpackChunkName: "pages/_country/search/_type" */))
const _b7a69dfc = () => interopDefault(import('../pages/_country/update-password/_token.vue' /* webpackChunkName: "pages/_country/update-password/_token" */))
const _964ef8fe = () => interopDefault(import('../pages/_country/checkout/_code/result.vue' /* webpackChunkName: "pages/_country/checkout/_code/result" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _57062649,
    name: "login"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/:country",
    component: _1843b62c,
    name: "country"
  }, {
    path: "/:country/about-us",
    component: _283b83f9,
    name: "country-about-us"
  }, {
    path: "/:country/bag",
    component: _a64ae5c0,
    name: "country-bag"
  }, {
    path: "/:country/become-a-dealer",
    component: _27b8fbb4,
    name: "country-become-a-dealer"
  }, {
    path: "/:country/contact-us",
    component: _c1d59bfa,
    name: "country-contact-us"
  }, {
    path: "/:country/cookie-policy",
    component: _1842a75a,
    name: "country-cookie-policy"
  }, {
    path: "/:country/how-to-buy",
    component: _3b6f57e9,
    name: "country-how-to-buy"
  }, {
    path: "/:country/login",
    component: _84f46ffe,
    name: "country-login"
  }, {
    path: "/:country/manuals",
    component: _000056c8,
    name: "country-manuals"
  }, {
    path: "/:country/my-account",
    component: _3b25f6b2,
    name: "country-my-account"
  }, {
    path: "/:country/news",
    component: _4943d3ae,
    name: "country-news"
  }, {
    path: "/:country/privacy-policy",
    component: _71e27faf,
    name: "country-privacy-policy"
  }, {
    path: "/:country/recovery",
    component: _e27965a6,
    name: "country-recovery"
  }, {
    path: "/:country/register",
    component: _9cd2758a,
    name: "country-register"
  }, {
    path: "/:country/shipping-policy",
    component: _56b526c9,
    name: "country-shipping-policy"
  }, {
    path: "/:country/stores",
    component: _d4060a6c,
    name: "country-stores"
  }, {
    path: "/:country/subscriber-verify",
    component: _570e30d4,
    name: "country-subscriber-verify"
  }, {
    path: "/:country/terms",
    component: _c44839c2,
    name: "country-terms"
  }, {
    path: "/:country/test-drive",
    component: _00a61d27,
    name: "country-test-drive"
  }, {
    path: "/:country/warranty",
    component: _6e6679d8,
    name: "country-warranty"
  }, {
    path: "/:country/work-with-us",
    component: _e60300b8,
    name: "country-work-with-us"
  }, {
    path: "/:country/my-account/addresses",
    component: _4830ba3a,
    name: "country-my-account-addresses"
  }, {
    path: "/:country/my-account/bikes",
    component: _eb3fec6c,
    name: "country-my-account-bikes"
  }, {
    path: "/:country/my-account/orders",
    component: _362f3ee3,
    name: "country-my-account-orders"
  }, {
    path: "/:country/my-account/orders/:code",
    component: _49185ca0,
    name: "country-my-account-orders-code"
  }, {
    path: "/:country/checkout/:code",
    component: _4202acc4,
    name: "country-checkout-code"
  }, {
    path: "/:country/news/:slug_id",
    component: _ddd10678,
    name: "country-news-slug_id"
  }, {
    path: "/:country/products/:slug?",
    component: _30271092,
    name: "country-products-slug"
  }, {
    path: "/:country/search/:type?",
    component: _6b56a7ec,
    name: "country-search-type"
  }, {
    path: "/:country/update-password/:token?",
    component: _b7a69dfc,
    name: "country-update-password-token"
  }, {
    path: "/:country/checkout/:code?/result",
    component: _964ef8fe,
    name: "country-checkout-code-result"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
